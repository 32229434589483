<script>
import { Navigation, Parallax } from 'swiper';

export default {

    name: 'StageSlider',

    props: {

    },

    data: () => {
        return {
            swiperModules: [
                Navigation,
                Parallax
            ],

        };
    },

    computed: {
        hasNavigation() {
            return this.$slots['navigation-buttons']
                && this.$slots.default()
                && this.$slots.default().length > 1
        },

        showButtons() {

            return this.$slots.default()
                && this.$slots.default().length > 1;
        },
    },

    methods: {

        handleButtonPositions(swiper) {

            if (!(swiper && swiper.navigation)) {
                return;
            }

            // const activeIndex = swiper.activeIndex;
            // const activeSlide = swiper.slides[activeIndex];

            // const mediaElement = activeSlide.querySelector('.stage-slider__image');

            // if (!mediaElement) {
            //     return;
            // }

            // const mediaBounding = mediaElement.getBoundingClientRect();
            // const mediaHeight = mediaBounding.height;
            // const mediaRight = mediaBounding.left + mediaBounding.width;
            // const slideshowSection = this.$refs['stageSlider'].closest('section')

            // slideshowSection.style.setProperty('--media-height', `${mediaHeight}px`);
            // slideshowSection.style.setProperty('--media-right', `${mediaRight}px`);


        }
    },
}
</script>

<template>
    <div class="stage-slider" ref="stageSlider">

        <swiper
            :modules="swiperModules"
            :autoHeight="true"
            :navigation="{
                nextEl: '.stage-slider__navigation-button-next',
                prevEl: '.stage-slider__navigation-button-prev',
            }"
            @after-init="handleButtonPositions"
            @slide-change="handleButtonPositions"
            @resize="handleButtonPositions"
        >
            <div v-show="showButtons" class="stage-slider__navigation-buttons">
                <slot name="navigation-buttons" />
            </div>
            <slot />
        </swiper>
    </div>
</template>

<style lang="scss">

</style>
