import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export const init = () => {


    // const textSectionMediaElements = gsap.utils.toArray(".section-text .media__media-element");
    const textSectionMediaElements = gsap.utils.toArray(".section-text .media");

    textSectionMediaElements.forEach(function(elem){


        // const eventType = elem.nodeName.toUpperCase() == 'IMG' ? 'load' : 'loadedmetadata';

        elem.style.transformOrigin="top center";
        // elem.addEventListener(eventType, () => {

            gsap.fromTo(elem,
                {
                    scale: 1.5
                    // 'width': '100vw',
                    // 'left': '50%',
                    // 'x': '-50vw',
                },
                {
                    scrollTrigger: {
                        trigger: elem,
                        scrub: true,
                        start: 'top 80%',
                        end: 'center center',
                        invalidateOnRefresh: true
                    },
                    scale: 1
                    // 'width': '100%',
                    // 'left': '50%',
                    // 'x': '-50%',
                }
            );
        // })
    });

}

export default init;




