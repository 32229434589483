<script lang="ts" setup>
import { onMounted, ref } from "vue";
import axios from "axios";
import leaflet from "leaflet";
import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerIconRetina from "leaflet/dist/images/marker-icon-2x.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";

import "leaflet/dist/leaflet.css";

const props = defineProps<{
    aspectRatio: number;
    minHeight: number;
    maxHeight: number;
    locationQuery?: string;
    fallbackLatitude: number;
    fallbackLongitude: number;
    zoom: number;
    label: string;
}>();

const rootElement = ref<HTMLElement>();

onMounted(async () => {
    const { data } = props.locationQuery
        ? await axios.get(
              `https://nominatim.openstreetmap.org/search.php?q=${props.locationQuery}&format=jsonv2`
          )
        : { data: undefined };

    const latitude = data?.[0]?.lat ?? props.fallbackLatitude;
    const longitude = data?.[0]?.lon ?? props.fallbackLongitude;

    const map = leaflet.map(rootElement.value!);
    map.setView([latitude, longitude], props.zoom);
    map.zoomControl.remove();
    map.attributionControl
        .setPrefix(false)
        .addAttribution(
            '© <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        );

    leaflet
        .tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png")
        .addTo(map);

    leaflet
        .marker([latitude, longitude], {
            icon: leaflet.icon({
                ...leaflet.Icon.Default.prototype.options,
                iconUrl: markerIcon,
                iconRetinaUrl: markerIconRetina,
                shadowUrl: markerShadow,
                shadowRetinaUrl: markerShadow,
            }),
        })
        .addTo(map)
        .bindPopup(props.label, {
            closeOnEscapeKey: false,
            closeOnClick: false,
        })
        .openPopup();
});
</script>

<template>
    <div
        ref="rootElement"
        :style="{
            aspectRatio: `${aspectRatio}`,
            minHeight: `${minHeight}px`,
            maxHeight: `${maxHeight}px`,
        }"
        class="project-map"
    />
</template>
