import "./scss/global.scss";
import 'swiper/css';

import { ExpandTransition, FadeTransition } from '@bam/transitions';
import { ABSlider } from '@bam/abslider';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { createApp } from 'vue';
import { useBreakpoints } from '@vueuse/core';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import * as ANIMATIONS from './animations';

import axios from 'axios'

gsap.registerPlugin(ScrollTrigger);


const components = import.meta.globEager('./components/**/*.vue')

const bps = JSON.parse(getComputedStyle(document.body).getPropertyValue('--bam-breakpoints'));
const breakpoints = useBreakpoints(bps);


const resizeObserver = new ResizeObserver(entries => {
    window.setTimeout(() => {
        ScrollTrigger.refresh();
    }, 0)
});

resizeObserver.observe(document.body)

Promise.all(Array.from(document.images).filter(img => !img.complete).map(img => new Promise(resolve => { img.onload = img.onerror = resolve; }))).then(() => {
    window.setTimeout(() => {
        ScrollTrigger.refresh();
    }, 0)
});






const app = createApp({
    data: () => {
        return {
            swiperModules: [
                Navigation,
                Pagination
            ],
            overlays: {},
            isInMobileNavBp: breakpoints.smaller('mobile-menu-bp')
        };
    },

    mounted() {

        ANIMATIONS.initHeader();
        ANIMATIONS.initPinnedMedia();
        ANIMATIONS.initCards();
        ANIMATIONS.initIcons();
        ANIMATIONS.initMediaContent();
        ANIMATIONS.initTextMedia();
        ANIMATIONS.initHistory();

        window.sc = ScrollTrigger;

        document.querySelectorAll('.section-big-link__link-element[data-overlay]').forEach(link => link.addEventListener('click', this.handleBigLink));
    },

    provide() {
        return {
            registerOverlay: this.registerOverlay,
            deRegisterOverlay: this.deRegisterOverlay,
            getOverlay: this.getOverlay,
        }
    },

    beforeUnmount() {
        document.querySelectorAll('.section-big-link__link-element[data-overlay]').forEach(link => link.removeEventListener('click', this.handleBigLink));
    },

    methods: {

        registerOverlay(id, overlay) {
            this.overlays[id] = overlay;
        },

        deRegisterOverlay(id) {
            delete this.overlays[id];
        },

        getOverlay(id) {
            return this.overlays[id];
        },

        handleBigLink(event) {

            event.preventDefault();

            const overlayId = event.target.dataset.overlay?.trim()
            const overlay = this.getOverlay(overlayId);

            if (overlay) {
                overlay.openOverlay();
            }

        }
    }
})


app.config.globalProperties.$breakpoints = breakpoints;
app.config.globalProperties.$gsap = gsap;

app.config.globalProperties.$axios = axios.create({
    baseURL: '',
    withCredentials: true,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': window.csrfTokenValue,
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
     }
});

Object.entries(components).forEach(([ path, definition ]) => {
    const componentName = path.split('/').pop().replace(/\.\w+$/, '')
    app.component(componentName, definition.default)
});

app.component('expand-transition', ExpandTransition);
app.component('fade-transition', FadeTransition);

app.component('swiper', Swiper);
app.component('swiper-slide', SwiperSlide);
app.component('ab-slider', ABSlider);

app.mount('#app');
