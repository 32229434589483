import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);


export const init = () => {

    const cardSections = gsap.utils.toArray(".section-cards");

    cardSections.forEach(function(cardSection){

        ScrollTrigger.batch(cardSection.querySelectorAll(".card"), {
            interval: 0.1,
            batchMax: 3,
            invalidateOnRefresh: true,
            start: 'top bottom',
            end: 'bottom top',
            onEnter: batch => gsap.to(batch, { autoAlpha: 1, y: 0, stagger: 0.15, overwrite: true, ease: "expo", duration: 1.25}),
            onLeaveBack: batch => gsap.set(batch, { autoAlpha: 0, y: '300px', stagger: 0, overwrite: true, ease: "expo", duration: 0}),

            onEnterBack: batch => gsap.to(batch, { autoAlpha: 1, y: 0, stagger: 0.15, overwrite: true, ease: "expo", duration: 1.25}),
            onLeave: batch => gsap.set(batch, { autoAlpha: 0, y: '-300px', stagger: 0, overwrite: true, ease: "expo", duration: 0})
        });

    });
}

export default init;
