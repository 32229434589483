<script lang="ts" setup>
import { computed, ref, watch } from "vue";
import { autoResetRef, useClamp, useElementSize } from "@vueuse/core";

const props = defineProps<{
    periodUnitSingular: string;
    periodUnitPlural: string;
    periodMin: number;
    periodMax: number;
    periodStep: number;
    amountLabel: string;
    amountMin: number;
    yield: number;
    yieldLabel: string;
    recommendationTemplate: string;
    recommendationAmount: number;
}>();

const amount = ref<number | "">("");
const amountClamped = useClamp((amount as any) || 0, props.amountMin, Infinity);

const period = ref(
    Math.floor((props.periodMax - props.periodMin) / props.periodStep / 2) *
        props.periodStep +
        props.periodMin
);

const calcYieldFormatted = (k: number, p: number, n: number) =>
    (k * Math.pow(1 + p / 100, n)).toLocaleString("de-DE", {
        useGrouping: false,
        maximumFractionDigits: 0,
    });

const yieldResult = computed(() =>
    calcYieldFormatted(amountClamped.value, props.yield, period.value)
);
const recommendationYieldResult = computed(() =>
    calcYieldFormatted(
        amountClamped.value + props.recommendationAmount,
        props.yield,
        period.value
    )
);

const tooltipContainerElement = ref<HTMLElement>();
const { width: tooltipContainerElementWidth } = useElementSize(
    tooltipContainerElement
);

const tooltipX = computed(
    () =>
        ((period.value - props.periodMin) /
            (props.periodMax - props.periodMin)) *
        tooltipContainerElementWidth.value
);
const tooltipSkewX = autoResetRef(0, 100);

watch(
    period,
    (nextPeriod, prevPeriod) =>
        (tooltipSkewX.value = nextPeriod > prevPeriod ? 10 : -10)
);
</script>

<template>
    <div class="investment-calculator">
        <div
            ref="tooltipContainerElement"
            class="investment-calculator__period-tooltip-container"
        >
            <div
                :style="{ transform: `translateX(calc(-50% + ${tooltipX}px))` }"
                class="investment-calculator__period-tooltip"
            >
                <div
                    :style="{ transform: `skewX(${tooltipSkewX}deg)` }"
                    class="investment-calculator__period-tooltip-background"
                />

                <div class="investment-calculator__period-tooltip-number">
                    {{ period }}
                </div>

                <div class="investment-calculator__period-tooltip-unit">
                    {{ period === 1 ? periodUnitSingular : periodUnitPlural }}
                </div>
            </div>
        </div>

        <div class="investment-calculator__amount-field from-left">
            <input
                v-model.number="amount"
                type="number"
                :placeholder="amountMin.toString()"
                :min="1"
                class="investment-calculator__amount-field from-left"
            />

            <div class="from-left">€</div>
        </div>

        <div class="investment-calculator__amount-label from-left">
            {{ amountLabel }}
        </div>

        <input
            v-model.number="period"
            type="range"
            :min="periodMin"
            :max="periodMax"
            :step="periodStep"
            class="investment-calculator__period"
        />

        <div
            :style="{ minWidth: `${yieldResult.length + 1}ch` }"
            class="investment-calculator__yield-value from-right"
        >
            {{ yieldResult }}€
        </div>

        <div class="investment-calculator__yield-label from-right">
            {{ yieldLabel }}
        </div>

        <p
            class="investment-calculator__recommendation"
            v-html="
                recommendationTemplate
                    .replace(
                        '{amount}',
                        `<span>${recommendationAmount}€</span>`
                    )
                    .replace(
                        '{yield}',
                        `<span>${recommendationYieldResult}€</span>`
                    )
            "
        />
    </div>
</template>
